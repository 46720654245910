.report-container {
  margin-top: 25px;
  width: 100%;
  height: 75vh;
}

.report-container > iframe {
  border-width: 0px;
  box-shadow: 0px 3.5px 20px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  box-sizing: border-box;
}
